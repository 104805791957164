<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :footer-props="finalFooterOptions"
    :search="finalSearch"
    :options.sync="defaultOptions"
    :page.sync="defaultOptions.page"
    :items-per-page.sync="defaultOptions.itemsPerPage"
    class="moon-table"
  >
    <template v-slot:top="{ options, updateOptions }">
      <v-row class="pa-3">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            outlined
            v-model="defaultSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            data-testid="filter-search"
          ></v-text-field>
        </v-col>
      </v-row>
      <SortMobile
        :headers="headers"
        :options="options"
        @update:options="updateOptions"
        v-if="!$vuetify.breakpoint.smAndUp"
      />
    </template>

    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>

    <!--    <template  v-for="(index, name) in $slots" v-slot:[name] >-->
    <!--      <slot v-if="name === 'no-data'" :name="name"></slot>-->
    <!--    </template>-->
  </v-data-table>
</template>

<script>
import { get, forEach, has, isNull, omit } from "lodash"
import SortMobile from "@/components/table/SortMobile"

export default {
  name: "DataTableExtended",

  inheritAttrs: false,

  props: {
    customSearch: {
      type: String,
      default: "",
    },
    keep: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    footerOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    withUrlParams: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    SortMobile,
  },

  data() {
    return {
      skipParams: {
        keep: null,
        p: 1,
        pp: 10,
      },
      defaultSearch: "",
      defaultOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      defaultFooterOptions: {
        "items-per-page-text": "",
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
      itemsCount: 0,
    }
  },

  created() {
    this.defaultOptions = { ...this.defaultOptions, ...this.options }
    this.initRoute()
    // Redirect to first page if after filters we have less results than we can show on current page
    this.$watch(
      () => this.$attrs["server-items-length"],
      this.onServerItemsLengthChange
    )
  },

  watch: {
    defaultOptions: {
      handler() {
        this.refreshTableData()
      },
      deep: true,
    },
    finalSearch: function () {
      this.refreshTableData()
    },
  },

  methods: {
    onServerItemsLengthChange(val) {
      if (
        this.defaultOptions.page &&
        val < this.defaultOptions.page * this.defaultOptions.itemsPerPage
      ) {
        this.defaultOptions.page = 1
      }
    },
    /**
     * Get table components options, for send to API for get table data
     *
     * @returns {{params: {p, pp: number, keep: *}}}
     */
    getTableComponentsParams() {
      let self = this
      const { sortBy, sortDesc, page, itemsPerPage } = this.defaultOptions
      let getParams = {
        p: page || 1,
        pp: itemsPerPage,
        keep: self.keep,
      }
      if (self.finalSearch !== "") {
        getParams.search = self.finalSearch
      }
      if (sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0) {
        getParams.sort = get(sortBy, "[0]")
        getParams.order = get(sortDesc, "[0]") ? "DESC" : "ASC"
      }
      return getParams
    },
    /**
     * Get params. for send to request API endpoint, component options merged with URL query params
     *
     * @returns {{params: {p, pp: number, keep: *}}}
     */
    getTableServerParams() {
      return { ...this.getTableComponentsParams(), ...this.$route.query }
    },
    /**
     * Update route with query params
     * @returns {Promise<unknown>}
     */
    updateRoute() {
      let self = this
      return new Promise((resolve) => {
        if (self.withUrlParams) {
          let query = self.getQueryParams()
          self.$router
            .replace({ name: self.$route.name, query: query })
            .then(() => {
              resolve()
            })
            .catch(() => {
              // catch error
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    /**
     * Get query params for insert in route and skip those that are setted in skipParams by logic:
     * - if param value is null skip
     * - if param value is setted check by value
     *
     * @returns {{[p: string]: *}}
     */
    getQueryParams() {
      let self = this
      let query = {}
      let rawQueryParams = self.getTableComponentsParams()
      forEach(rawQueryParams, (val, key) => {
        if (
          !val ||
          (has(self.skipParams, key) &&
            (isNull(self.skipParams[key]) || val === self.skipParams[key]))
        ) {
          rawQueryParams = omit(rawQueryParams, key)
        }
      })
      query = { ...query, ...rawQueryParams }
      return query
    },
    /**
     * Refresh route and table with pagination, sort, filter data
     */
    refreshTableData() {
      let self = this
      self.updateRoute().then(() => {
        self.$emit("init-table-data")
      })
    },

    /**
     * Get params from URL and assign to params of table options
     */
    initRoute() {
      let self = this
      let query = self.$route.query
      if (query.p) {
        self.defaultOptions.page = parseInt(query.p)
      }
      if (query.pp) {
        self.options.itemsPerPage = parseInt(query.pp)
      }
      if (query.sort) {
        self.options.sortBy = [query.sort]
      }
      if (query.order) {
        self.options.sortDesc = [query.order === "DESC" ? true : false]
      }
      if (query.search) {
        self.$emit("update-search", query.search)
      }
    },
  },

  computed: {
    finalSearch() {
      if (this.defaultSearch !== "") return this.defaultSearch
      if (this.customSearch !== "") return this.customSearch
      return this.defaultSearch
    },
    finalFooterOptions() {
      return { ...this.defaultFooterOptions, ...this.footerOptions }
    },
  },
}
</script>
