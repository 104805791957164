<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form data-vv-scope="form-janus-servers" data-testid="form-janus-servers">
      <v-card>
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer />
          <v-switch
            v-model="activeItem.is_active"
            label="Active"
            input-value="true"
            data-vv-validate-on="change"
            data-testid="switch-janus-is-active"
          ></v-switch>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  outlined
                  v-model="activeItem.protocol"
                  :items="protocols_list"
                  item-text="name"
                  item-value="pk"
                  :menu-props="{ maxHeight: '400' }"
                  label="Protocol"
                  hint="Url protocol"
                  persistent-hint
                  :error-messages="
                    errors.collect('form-janus-servers.protocol')
                  "
                  data-vv-name="protocol"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  data-testid="switch-janus-is-active"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Hostname"
                  v-model="activeItem.hostname"
                  :error-messages="
                    errors.collect('form-janus-servers.hostname')
                  "
                  v-validate="{
                    required: true,
                    regex: /^((?!-)[A-Za-z0-9-]{1,63}(?!-)\.)+[A-Za-z]{2,6}$/,
                  }"
                  data-vv-name="hostname"
                  data-vv-validate-on="change"
                  data-testid="hostname"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  label="Port"
                  v-model="activeItem.port"
                  :error-messages="errors.collect('form-janus-servers.port')"
                  v-validate="{ required: true, max: 5, min_value: 1 }"
                  data-vv-name="port"
                  data-vv-validate-on="change"
                  data-testid="port"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  label="Name"
                  v-model="activeItem.name"
                  :error-messages="errors.collect('form-janus-servers.name')"
                  v-validate="'required'"
                  data-vv-name="name"
                  data-vv-validate-on="change"
                  data-testid="name"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Real IP"
                  v-model="activeItem.real_ip"
                  :error-messages="errors.collect('form-janus-servers.real_ip')"
                  data-vv-name="real_ip"
                  v-validate="'required|validIp'"
                  data-vv-validate-on="change"
                  data-testid="real-ip"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  outlined
                  v-model="activeItem.role"
                  :items="janus_roles_list"
                  :menu-props="{ maxHeight: '400' }"
                  label="Role"
                  :error-messages="errors.collect('form-janus-servers.role')"
                  data-vv-name="role"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  data-testid="role"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  v-model="activeItem.balancing_group"
                  :items="balancing_groups_list"
                  :menu-props="{ maxHeight: '400' }"
                  label="Balancing group"
                  :error-messages="
                    errors.collect('form-janus-servers.balancing_group')
                  "
                  data-vv-name="balancing_group"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="dialogBalancingGroup = true"
                  data-testid="balancing-group"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.native="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.show = false"> Close </v-btn>
    </v-snackbar>

    <BalancingGroupDialog
      :dialog.sync="dialogBalancingGroup"
      @save-balancing-group="saveBalancingGroup"
    ></BalancingGroupDialog>
  </v-dialog>
</template>

<script>
import "@/validators/ip/ip-validator"
import BalancingGroupDialog from "../BalancingGroupDialog"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  components: {
    BalancingGroupDialog,
  },
  data() {
    return {
      protocols_list: [
        { pk: "https", name: "https" },
        { pk: "http", name: "http" },
      ],
      janus_roles_list: ["publisher", "subscriber"],
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "Profile succesfully saved.",
        color: "success",
      },
      balancing_groups_list: [],
      dialogBalancingGroup: false,
    }
  },
  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getBalancingGroupList()
      }
    },
  },
  methods: {
    getTitle() {
      return this.editedIndex == -1
        ? "Add new janus server"
        : `<span class="blue-grey--text text--lighten-4">Edit janus server</span> ${this.activeItem.name}`
    },

    createJanusServer(data) {
      let app = this
      app.$validator.errors.clear("form-janus-servers")
      app.$validator.validateAll("form-janus-servers").then((result) => {
        if (result) {
          app.$store
            .dispatch("janusServersCreateAction", data)
            .then(({ data }) => {
              app.$emit("janus-server-data-saved")
              app.closeDialog()
            })
            .catch(({ response }) => {
              app.handleValidationErrors(response)
            })
        }
      })
    },

    editJanusServer(data) {
      let app = this
      app.$validator.errors.clear("form-janus-servers")
      app.$validator.validateAll("form-janus-servers").then((result) => {
        if (result) {
          app.$store
            .dispatch("janusServersEditAction", data)
            .then(({ data }) => {
              app.$emit("janus-server-data-saved")
              app.closeDialog()
            })
            .catch(({ response }) => {
              //app.handleValidationErrors(response);
              //console.log('error on editJanusServer');
            })
        }
      })
    },

    save() {
      let app = this
      let formData = {
        data: {
          type: "janus_server",
          data: {
            name: app.activeItem.name,
            protocol: app.activeItem.protocol,
            hostname: app.activeItem.hostname,
            port: app.activeItem.port,
            is_active: app.activeItem.is_active,
            real_ip: app.activeItem.real_ip,
            balancing_group: app.activeItem.balancing_group,
            role: app.activeItem.role,
          },
        },
      }

      if (this.editedIndex > -1) {
        formData["slug"] = this.editedIndex
        app.editJanusServer(formData)
      } else {
        app.createJanusServer(formData)
      }
    },

    deleteJanusServer(item) {
      let app = this
      app.$store.dispatch("saveTableLoaderAction", true)
      app.$store.dispatch("janusServersDeleteAction", item).then(({ data }) => {
        app.$emit("janus-server-data-saved")
        app.$store.dispatch("saveTableLoaderAction", false)
      })
    },

    closeDialog() {
      let app = this
      this.$emit("update:dialog", false)
      app.$validator.errors.clear("form-janus-servers")
    },

    toggleAll() {
      if (this.selectedEngines.length) this.selectedEngines = []
      else this.selectedEngines = this.engines.slice()
    },

    handleValidationErrors(response) {
      let self = this
      response.data.forEach((val, key) => {
        self.$validator.errors.add({
          scope: "form-janus-servers",
          field: key,
          msg: val,
        })
      })
    },

    getBalancingGroupList() {
      let app = this
      app.$store
        .dispatch("balancingGroupsGetListAction")
        .then(({ data }) => {
          app.balancing_groups_list = data.data.balancing_groups
        })
        .catch(({ response }) => {
          app.handleValidationErrors(response)
        })
    },

    saveBalancingGroup(name) {
      this.balancing_groups_list.push(name)
      this.activeItem.balancing_group = name
    },
  },
}
</script>
