<template>
  <v-dialog v-model="dialogBalancing" max-width="500px">
    <v-card data-testid="container-balancing-group">
      <v-card-title>
        <span class="headline">Add balancing group</span>
      </v-card-title>
      <v-card-text>
        <v-form
          data-vv-scope="form-balancing-group"
          data-testid="form-balancing-group"
        >
          <v-text-field
            outlined
            label="Name"
            v-model="name"
            v-validate="'required'"
            :error-messages="errors.collect('form-balancing-group.name')"
            data-vv-name="name"
            data-vv-validate-on="change"
            data-testid="balancing-group-name"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$emit('update:dialog', false)"
          data-testid="data-cancel"
        >
          Cancel
        </v-btn>
        <v-btn color="success" text @click="save()" data-testid="data-save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
    }
  },
  watch: {
    dialogBalancing: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.$validator.errors.clear("form-balancing-group")
      }
    },
  },
  methods: {
    save() {
      let app = this
      app.$validator.validateAll("form-balancing-group").then((result) => {
        if (result) {
          app.$emit("update:dialog", false)
          app.$emit("save-balancing-group", app.name)
        }
      })
    },
  },
  computed: {
    dialogBalancing: {
      get() {
        return this.dialog
      },
      set(val) {
        this.$emit("update:dialog", val)
      },
    },
  },
}
</script>
