<template>
  <div>
    <v-row class="px-3 pb-3">
      <v-col cols="12" sm="12">
        <div class="tableSort">
          <v-select
            class="tableSort-select"
            :items="headersComputed"
            label="Sort by"
            outlined
            v-model="selectedSortBy"
            item-text="text"
            item-value="value"
            :menu-props="{ maxHeight: '300', light: false }"
            clearable
            hide-details
          />
          <v-btn-toggle
            class="tableSort-buttons"
            v-model="sortedState"
            tile
            color="blue darken-2"
            group
            :mandatory="!!selectedSortBy"
          >
            <v-btn
              :disabled="!selectedSortBy"
              icon
              @click="sortedState = false"
              :value="false"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn
              :disabled="!selectedSortBy"
              icon
              @click="sortedState = true"
              :value="true"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "SortMobileTable",

  props: {
    options: {
      type: Object,
    },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data: () => ({
    sortBy: [],
    sortDesc: [],
  }),

  watch: {
    options(val) {
      if (this.sortBy !== val.sortBy) {
        this.sortBy = val.sortBy
      }
      if (this.sortDesc !== val.sortDesc) {
        this.sortDesc = val.sortDesc
      }
    },
  },

  computed: {
    headersComputed() {
      let sorted = this.headers.map((h) => {
        if (h.sortable) return h
      })
      let headers = sorted.filter(function (el) {
        return el != undefined
      })
      return headers
    },
    sortedState: {
      get() {
        let state = undefined
        if (this.sortDesc.length > 0) {
          state = !this.sortDesc[0] ? false : true
        }
        return state
      },
      set(val) {
        this.$emit("update:options", {
          options: {
            sortBy: this.sortBy,
            sortDesc: [val],
          },
          sortBy: this.sortBy,
          sortDesc: [val],
        })
      },
    },
    selectedSortBy: {
      get() {
        return this.sortBy.length > 0 ? this.sortBy[0] : null
      },
      set(val) {
        this.$emit("update:options", {
          options: {
            sortBy: val ? [val] : [],
            sortDesc: [false],
          },
          sortBy: val ? [val] : [],
          sortDesc: [false],
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tableSort {
  display: flex;
  align-items: center;

  .tableSort-select {
    flex: 0 1 auto;
  }

  .tableSort-buttons {
    flex: 0 0 auto;
  }
}
</style>
