<template>
  <div class="d-flex flex-column align-center ma-1 vertivalButton" v-if="route">
    <v-btn icon :color="color" :to="route" :disabled="disabled">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
    <p class="mb-0 vertivalButton-text" v-if="route" :to="route">
      {{ text }}
    </p>
  </div>

  <div v-else class="d-flex flex-column align-center ma-1 vertivalButton">
    <v-btn icon :color="color" :disabled="disabled">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
    <p class="mb-0 vertivalButton-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "VerticalButton",
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    route: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.vertivalButton {
  .vertivalButton-text {
    max-width: 90px;
    text-align: center;
    font-size: 10px !important;
  }
}
</style>
