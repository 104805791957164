export default {
  data() {
    return {}
  },
  methods: {},
  computed: {},
  beforeRouteLeave(to, from, next) {
    if (this.dialog) {
      this.close()
      next(false)
    } else {
      next()
    }
  },
}
