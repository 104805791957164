var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-toolbar',[_c('v-card-title',[_vm._v("Janus servers")]),_c('v-spacer'),(
          _vm.$can('create', 'settings_multi') || _vm.$can('create', 'Superadmin')
        )?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp,"data-testid":"btn-create-janus-server"},on:{"click":function($event){$event.stopPropagation();return _vm.createJanusServer()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('JanusServersDialogCrud',{ref:"refDialogJanusServer",attrs:{"dialog":_vm.dialog,"editedIndex":_vm.editedIndex,"activeItem":_vm.activeItem},on:{"update:dialog":function($event){_vm.dialog=$event},"janus-server-data-saved":_vm.fetchTableData}})],1),_c('v-card-text',[_c('DataTableExtended',{ref:"table",staticClass:"elevation-0 border",attrs:{"calculate-widths":true,"headers":_vm.headers,"items":_vm.janusServersParsed,"server-items-length":_vm.janusServersCount,"loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"customSearch":_vm.filterSearch,"data-testid":"table-janus-servers"},on:{"init-table-data":_vm.fetchTableData,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function({ options, updateOptions }){return [_c('v-row',{staticClass:"px-3 pt-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''),attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","data-testid":"filter-search"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('SortMobile',{attrs:{"headers":_vm.headers,"options":options},on:{"update:options":updateOptions}}):_vm._e()]}},{key:`item.data.name`,fn:function({ item }){return [_c('div',{staticStyle:{"min-width":"120px"},attrs:{"data-testid":"janus-name"}},[_vm._v(" "+_vm._s(item.data.name)+" ")])]}},{key:`item.data.hostname`,fn:function({ item }){return [_c('div',{attrs:{"data-testid":"janus-url"}},[_vm._v(" "+_vm._s(item.data.url)+" ")])]}},{key:`item.menu`,fn:function({ item }){return [_c('v-menu',{attrs:{"transition":"slide-y-transition","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                  _vm.$can('update', 'settings_multi') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"clear-padding",attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":_vm.Pallette.actionButtons.menu,"data-testid":"btn-janus-actions-more"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(
                  _vm.$can('update', 'settings_multi') ||
                  _vm.$can('delete', 'settings_multi') ||
                  _vm.$can('update', 'Superadmin') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('v-list-item',{attrs:{"data-testid":"btn-edit-janus-server"},on:{"click":function($event){return _vm.editJanusServer(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.edit}},[_vm._v(" mdi-pencil ")])],1)],1):_vm._e(),(
                  _vm.$can('delete', 'settings_multi') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('v-list-item',{attrs:{"data-testid":"btn-delete-janus-server"},on:{"click":function($event){return _vm.deleteJanusServer(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.delete}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1)],1)]}},(
            !_vm.$vuetify.breakpoint.smAndUp &&
            (_vm.$can('delete', 'settings_multi') ||
              _vm.$can('update', 'settings_multi') ||
              _vm.$can('update', 'Superadmin'))
          )?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[(
                  _vm.$can('update', 'settings_multi') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-pencil","text":"Edit","color":_vm.Pallette.actionButtons.edit,"data-testid":"btn-edit-janus-server"},nativeOn:{"click":function($event){return _vm.editJanusServer(item)}}}):_vm._e(),(
                  _vm.$can('delete', 'settings_multi') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-delete","text":"Delete","color":_vm.Pallette.actionButtons.delete,"data-testid":"btn-delete-janus-server"},nativeOn:{"click":function($event){return _vm.deleteJanusServer(item)}}}):_vm._e()],1)],1)]}}:null],null,true)})],1)],1),_c('Confirm',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }